import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from "./Registration.module.css";
import "../../assets/global.css";

// COMPONENTS
import { RegistrationLayout } from '../../layouts/RegistrationLayout';
// Hidden for now, however, may use this notificiation service still
// import { NotificationBox } from '../../components/NotificationBox';
import { FormRegisterNewUser } from '../../components/FormRegisterNewUser';

export const Registration = () => {
  return (
    <RegistrationLayout>
     <div className={styles['page-content']}>
        <div className={styles['flex-container']}>
        <Row className={styles['top-row']}>
          <Col className={styles['top-row-left']}>
            <img alt="" src={require("../../assets/img/logo-menu-1.png")} className={styles['reg-logo']} />
            <h3><span id="take-surveys">Take Surveys,</span> <span id="get-cards">Get Gift Cards.</span></h3>
            <p>Become a member of MySoapBox™. We collect feedback on the products and services you use every day. You collect points redeemable for gift cards at your favorite stores and restaurants! <br/><br/><b>Fill in the form below to get started.</b></p>
              <FormRegisterNewUser />
          </Col>
          <Col className={styles['top-row-right']}>
          </Col>
        </Row>
        {
        // TODO: Add in the background images for the bottom sections
        }
        <Row className={styles['second-row']}>
            <div className={styles['flex-container']}>
              <h2><b>How It Works</b></h2>
              <p>MySoapBox™ is an online community panel, providing a rich and highly engaged pool of respondents. Our multi-mode recruiting approach includes a broad mix of methods and sources while our respondent-centric relationship models result in a highly engaged pool of respondents, focusing on today's touch points and poised to evolve with the technology.</p>
              <Row className={styles['icon-box-row']}>
                <Col className={`${styles['icon-box']} ${styles['join-box']}`}><h4>Join</h4><p>Sign up today to get access to our community panel.</p></Col>
                <Col className={`${styles['icon-box']} ${styles['par-box']}`}><h4>Participate</h4><p>Give your opinion on surveys and earn points.</p></Col>
                <Col className={`${styles['icon-box']} ${styles['earn-box']}`}><h4>Earn</h4><p>Redeem your points for gift cards!</p></Col>
              </Row>
            </div>
        </Row>
        <Row className={styles['third-row']}>
            <div className={styles['flex-container']}>
                <h2><b>Featured Rewards</b></h2>
                <Row className={styles['gift-card-row']}>
                  <Col className={`${styles['card-box']} ${styles['card-best-buy']}`}></Col>
                  <Col className={`${styles['card-box']} ${styles['card-apple']}`}></Col>
                  <Col className={`${styles['card-box']} ${styles['card-wings']}`}></Col>
                  <Col className={`${styles['card-box']} ${styles['card-target']}`}></Col>
                  <Col className={`${styles['card-box']} ${styles['card-starbucks']}`}></Col>
                  <Col className={`${styles['card-box']} ${styles['card-amazon']}`}></Col>
                </Row>
            </div>
        </Row>
        <Row className={styles['fourth-row']}>
            <div className={styles['flex-container']}>
                <h2><b>Why join MySoapBox™?</b></h2>
                <Row className={styles['why-join-row']}>
                  <Col className={`${styles['icon-box']} ${styles['icon-engage']}`}><h4>Highest Engagement</h4></Col>
                  <Col className={`${styles['icon-box']} ${styles['icon-refer']}`}><h4>Quick Payment Processing</h4></Col>
                  <Col className={`${styles['icon-box']} ${styles['icon-quick']}`}><h4>Refer a Friend</h4></Col>
                  <Col className={`${styles['icon-box']} ${styles['icon-giveaway']}`}><h4>Monthly Point Giveaway</h4></Col>
                </Row>
            </div>
        </Row>
    </div>
    </div>
    </RegistrationLayout>
  );
};
