import React from "react";
import Navbar from "react-bootstrap/Navbar";

import "./style.css";

// TODO: remove unused loading and error
//
export const HeaderName = ({ userProfile, _loading, _error }) => {
  if (!userProfile) {
    return null;
  }

  const capitalizeFirst = str => {
    if (!str) { return ""; }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const firstName = capitalizeFirst(userProfile.data.first_name);
  const lastName = capitalizeFirst(userProfile.data.last_name);

  return (
    <Navbar.Text className="header-name-wrapper">
    <span className="header-text-name">{firstName}&nbsp;{lastName}</span><br/>
    <span className="header-text-points">{Intl.NumberFormat().format(userProfile.data.unreedem_user_points)} Points</span>
    </Navbar.Text>
  );
};
