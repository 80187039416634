import React from "react";
import homeIcon from "../../assets/img/home-icon.png";
import "./style.css";

export const NavHome = ({ property1, style }) => {
  return (
    <div
      className={"icon-home"}
      style={{ backgroundImage: `url(${homeIcon})`}}
    >
    </div>
  );
};
