import React from 'react'

import { Header } from '../../components/Header/Header';

import { Footer } from '../../components/Footer/Footer';

import Container from 'react-bootstrap/Container';

import styles from "./Layout.module.css";

export const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <div id={styles['layout-page-bg']}></div>
      <Container fluid className='page-wrapper' id={styles['layout-page-wrapper']}>


        {children}

      </Container>
      <Footer />

    </React.Fragment>


  );
};
