import React from "react";
import logoutIcon from "../../assets/img/logout-icon.png";
import "./style.css";

export const NavLogout = () => {
  return (
    <div
      className={"icon-logout"}
      style={{ backgroundImage: `url(${logoutIcon})`}}
    >
    </div>
  );
};
