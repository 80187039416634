import React from "react";
import faqIcon from "../../assets/img/faq-icon.png";
import "./style.css";

export const NavFaq = () => {
  return (
    <div
      className={"icon-faq"}
      style={{ backgroundImage: `url(${faqIcon})`}}
    >
    </div>
  );
};
