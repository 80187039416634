//API INSTANCE
import axiosInstance from './axiosInstance';

export const getSessions = async (authToken) => {
  try {
    const localDate = new Date();
    // Set the hours, minutes, and seconds to zero
    localDate.setHours(0, 0, 0, 1);
    // Convert the local date to UTC string format
    const startDate = localDate.toISOString();

    const response = await axiosInstance.post(`${process.env.REACT_APP_GET_SESSIONS_URL}`,
      { start_date: startDate },
      {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        }
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
