import React, { useContext } from 'react';
import { UserProfileContext } from '../../_contexts/UserProfileContext';

import { HeaderName } from '../../components/HeaderName';

export const HeaderNameWrapper = () => {
  const { userProfile, loading, error } = useContext(UserProfileContext);

  return (
    <HeaderName userProfile={userProfile} loading={loading} error={error} />
  );
};
