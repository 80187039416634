import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./AboutUs.module.css";

// COMPONENTS
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { AboutUsOverview } from "../../components/AboutUsOverview/AboutUsOverview";
import { AboutUsIcons } from "../../components/AboutUsIcons/AboutUsIcons";
import { AboutUsTip } from "../../components/AboutUsTip/AboutUsTip";
export const AboutUs = () => {
  return (
    <React.Fragment>
      <Header />
      <div className={`${styles['background']}`}>
        <div className={`${styles['page-content']}`}>
          <AboutUsOverview />
          <AboutUsIcons />
          <AboutUsTip />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};
