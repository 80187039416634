// App.jsx
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// COMPONENTS

import { Auth } from './components/Auth/';
import { ProtectedRoute }  from './hooks/ProtectedRoute';
import { PageNotFound } from './components/PageNotFound';


// PAGES
import { Dashboard } from "./pages/Dashboard";
import { Report } from "./pages/Report";
import { Registration } from "./pages/Registration";
import { TermsOfUse } from "./pages/TermsOfUse";
import { Support } from "./pages/Support";
import { AboutUs } from "./pages/AboutUs";

//GLOBAL STYLES
import './assets/global.css';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// apiInterceptor for error catching
import { useAxiosInterceptor } from './_apis/axiosInterceptor';
import axiosInstance from './_apis/axiosInstance';


const App = () => {
  const navigate = useNavigate();
  useAxiosInterceptor(axiosInstance, navigate);
  return (
    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/support" element={<Support />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route index element={<Dashboard />} />
        <Route path="/report" element={<Report />} />
        {/* Add more protected routes here as nested routes */}
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};


export default App;
