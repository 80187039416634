import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./TermsOfUse.module.css";

// COMPONENTS
import { Layout } from '../../layouts/Layout';
import { TermsOverview } from '../../components/TermsOverview';
import { TermsAccordion } from '../../components/TermsAccordion';

export const TermsOfUse = () => {
  return (
    <Layout>
     <div className={`${styles['page-content']}`}>
        <TermsOverview />
        <TermsAccordion />
    </div>
    </Layout>
  );
};
