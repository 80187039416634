import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container';

// COMPONENTS
import { HeaderNameWrapper } from '../../components/HeaderNameWrapper';
import { NavLogout } from "../NavLogout";
import { NavEditProfile } from "../NavEditProfile";
import { NavHome } from "../NavHome";

// import { NavNotification } from "../NavNotification";
import { NavFaq } from "../NavFaq";

// CONTEXT
import { useAuth } from '../../_contexts/AuthContext';
import { MenuMobile } from '../../components/MenuMobile';

import "./style.css";

export const Header = ({ userProfile, loading, error }) => {
  const { logout, currentUser } = useAuth();

  const handleLogout = () => {
    logout(currentUser.token);
  };

  return (
    <Navbar>
      <Container fluid>
        <MenuMobile />
        <Navbar.Brand href="/">
          <img
            alt=""
            src={require("../../assets/img/logo-menu-1.png")}
            className={"navbar-logo-menu desktop-logo"}
          />
          <img
            alt=""
            src={require("../../assets/img/mobile-logo.png")}
            className={"navbar-logo-menu mobile-logo"}
          />
        </Navbar.Brand>
        <Nav className="ms-auto">
          <Nav.Item>
          <Nav.Link href="/" className="home-header-link"><NavHome/></Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
          <Nav.Link href="#notifications"><NavNotification/></Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
          <Nav.Link href="https://www.mysoapbox.com/page/1639?page=profile" className="profile-header-link"><NavEditProfile /></Nav.Link>
          </Nav.Item>
          <Nav.Item>
          <Nav.Link href="https://www.mysoapbox.com/page/1639?page=faq" className="faq-header-link"><NavFaq /></Nav.Link>
          </Nav.Item>
          <Nav.Item>
          <Nav.Link onClick={handleLogout} className="logout-header-link"><NavLogout/></Nav.Link>
          </Nav.Item>
        </Nav>
        <HeaderNameWrapper />
      </Container>
    </Navbar>
  );
};
