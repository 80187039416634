import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';

import "./style.css";
import styles from "./FAQAccordion.module.css";

export const FAQAccordion = () => {
    return (
        <div className={`${styles['flex-container']}`}>
            <p className={`${styles['container-header']}`}>Frequently Asked Questions</p>
            <Accordion className={`${styles['faq-accordion']} faq-accordion`}>
                <Accordion.Item className={`${styles['accordion-item']}`} eventKey="0">
                    <Accordion.Header className={`${styles['accordion-header']}`}>General</Accordion.Header>
                    <Accordion.Body>
                        <ol className={`${styles['numbered-list']}`}>
                            <li>What is MySoapBox?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>MySoapBox, a member of the Interview Services of America (ISA) family, is an online membership community offering individuals a forum to voice their opinions through market research studies and activities. By joining MySoapBox you have the opportunity to voice your opinion and to help our clients improve the products, services, and experiences available in the marketplace.</li>
                                </ol>
                            </li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${styles['accordion-item']}`} eventKey="1">
                    <Accordion.Header className={`${styles['accordion-header']}`}>Membership and Account</Accordion.Header>
                    <Accordion.Body>
                        <ol className={`${styles['numbered-list']}`}>
                            <li>How do I join MySoapBox?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>Joining is easy and free! Simply answer a few questions, and we will send you an activation link. Once you opt-in from the activation link, you are ready to get started. You will receive your first survey invitation within 24 hours so you can start earning rewards right away!</li>
                                </ol>
                            </li>
                            <li>
                                Who is eligible to join?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>If you are over the age of 13, and can provide a valid mailing address and email address you are eligible to join</li>
                                </ol>
                            </li>
                            <li>How much does it cost to be a member?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>There is no cost to join or participate in any MySoapBox activities.</li>
                                </ol>
                            </li>
                            <li>I registered with MySoapBox. Why haven’t I received a confirmation email?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>Sometimes our confirmation emails get misplaced so you may need to check your SPAM or Junk folder. If for some reason you have not received it, click here to resend the confirmation.</li>
                                </ol>
                            </li>
                            <li>How many accounts can I have?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>You are allowed to have one MySoapBox account. Other members of your household may join, but there is only one account per person allowed. We do encourage you to refer your friends and family.</li>
                                </ol>
                            </li>
                            <li>How do I get invited to participate in research studies?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>You have the option to participate in different types of studies. The more types of studies you agree to be part of, the more you will get invited. You can also choose how we invite you. We will automatically invite you via email, but opting into other options for invitations such as text messages will ensure you are alerted right away when we have something for you to participate in so you don't miss out on your chance to earn points.</li>
                                </ol>
                            </li>
                            <li>Can I cancel my membership?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>Have you lost your mind? Why would you want to cancel? In all seriousness, you may cancel any time. If there is a specific issue, we would love to hear from you. You can cancel your MySoapBox membership by clicking the unsubscribe link in the footer of any MySoapBox email. Please note, by deactivating your account, you forfeit all points and your account may not be reactivated.</li>
                                </ol>
                            </li>
                            <li>How do I log into my account?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>Go to www.MySoapBox.com. On the top right hand side you can login to access you account. If you have forgotten your password, you can request it by clicking on the link. If you have not yet registered, you cannot log in and need to register first.</li>
                                </ol>
                            </li>
                            <li>How do I update my account and membership information?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>To update your account information, you must first log in to your account. Once you are logged in, you will see a "My Profile" tab appear in the upper menu. You can update your account information there.</li>
                                </ol>
                            </li>
                            <li>I forgot my password. How do I get a new one?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>Simply go to our homepage and click “Forgot Password?” You will then be taken through the process of resetting your password so you can log back in as quickly as possible.</li>
                                </ol>
                            </li>
                            <li>How do I update my profile?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>To update your member profile data, log into the MySoapBox website with your email and password and click on the “My Profile” tab in the upper menu.</li>
                                </ol>
                                </li>
                            <li>Can other people in my Household join?
                                <ol className={`${styles['alpha-list']}`}>
                                    <li>Absolutely. Even the ones you do not like. Everyone may only have one account, but any member of your household over the age of 13 who has a valid email and mailing address may join. You may refer family members and friends by Accessing the “Refer a Friend" tab in the upper menu.</li>
                                </ol>
                                </li>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${styles['accordion-item']}`} eventKey="2">
                    <Accordion.Header className={`${styles['accordion-header']}`}>Incentives and Rewards</Accordion.Header>
                    <Accordion.Body>
                        <ol className={`${styles['numbered-list']}`}>
                            <li>How do I earn points?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>You'll earn points for different activities. The amount of points you earn depends on the activity you participate in. We will always let you know how many points the activity is worth. You can also log into your account and see available activities, located on the “Surveys” tab in the upper menu. Once you have accumulated 16,000 points, you can cash your points in for prizes.</li>
                            </ol>
                            <li>How quickly can I get a prize?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>You will have the opportunity to earn points soon as new surveys frequently become available!</li>
                            </ol>
                            <li>How do I know how many points I have?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>When you are logged into your account, you can access the “Redeem” tab. This tab will show you how many points you have accumulated and also give you options for cashing in your rewards.</li>
                            </ol>
                            <li>How do I redeem my points?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>To redeem your points, you will first need to log into your account on MySoapBox and access the "Redeem" tab. Once you’ve accumulated a minimum of 16,000 points, you will be able to redeem your points for prizes. Additionally, after you reach your initial 16,000-point threshold, there is no minimum to redeem so long as you have enough points to cash in for one of our great prizes.</li>
                            </ol>
                            <li>Why do I have to earn 16,000 points before I can redeem?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>The 16,000-point minimum is to ensure that our respondents are here to stay and not here to make a quick buck and bail. We want MySoapBox to be a thriving community with people who want to be here and that are happy to be here. After the 16,000-point minimum is reached there are no longer any restrictions on cashing in points. Our average survey points range from 750 to 1,500.</li>
                            </ol>
                            <li>Why have I not received my points?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>You are eligible for points once you have completed a survey or other activity. Each time you are invited to participate, your invitations will specify when your points will be credited. With some surveys, the points are credited instantly while there may be a waiting period for others.</li>
                            </ol>
                            <li>I redeemed my points, but I have not received my reward.</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>We decided to keep your reward for ourselves. Only kidding! If you redeemed your points and have not received an email confirmation for your reward, please contact us by using the contact form on this page or by emailing support@mysoapbox.com, and we'll get it resolved right away.</li>
                            </ol>
                            <li>8. Is there a limit on how many points I can accumulate?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>No. You can continue to earn as many points as you want. Once you have earned a minimum of 16,000 points, you can cash in your points for rewards. If you are a US Resident and you redeem points with a cash equivalent value of $600 or more, you will be asked to provide your social security number as MySoapBox is required to issue you a 1099 tax form for that calendar year.</li>
                            </ol>
                            <li>What happens to my points if I no longer want to participate in research surveys?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Your MySoapBox rewards points will remain in your account as long as your account is active. If you stop participating, you will no longer receive points. You can still earn points for referring friends and families.</li>
                            </ol>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${styles['accordion-item']}`} eventKey="3">
                    <Accordion.Header className={`${styles['accordion-header']}`}>Surveys</Accordion.Header>
                    <Accordion.Body>
                        <ol className={`${styles['numbered-list']}`}>
                            <li>1. Why do you ask me the same questions in different surveys?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>A lot of the data we collect from you is only shared with our partners and clients on a singular summary or aggregate basis. These data formats describe the profile and demographics data of a MySoapBox member, or group of members, without specifically identifying an individual member. Therefore, when our research clients want this data, they must ask you to provide it to them directly. This is why you will be asked the same or similar questions in different surveys.</li>
                            </ol>
                            <li>How often will you contact me?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                As a member of MySoapBox, you have control on the ways in which we contact you and how often. Automatically we will email you with every opportunity available to you. However, if you would like to update your preferences on how often you’d like us to contact you please contact our support team and we can update your preferences.
                            </ol>
                            <li>Why can’t I access the survey link you sent me?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>We make every effort to make sure our survey links appear on a single line. In some cases, in your invitation, the survey link is broken into two lines. If that is the case, you will need to copy the entire link and paste it into your browser. You also may need to check the following on your computer:</li>
                                <ul className={`${styles['bullet-list']}`}>
                                    <li>Enable cookies</li>
                                    <li>Check that your ISP settings are not blocking the survey</li>
                                    <li>Remove Adware and/or Spyware from your system</li>
                                    <li>Make sure your browser is not blocking access to our site</li>
                                    <li>Make sure your anti-virus settings accept our site</li>
                                    <li>Check your firewall settings</li>
                                </ul>

                                <li>If you have no idea what any of that means, don't worry, most people don't. You can always contact our amazing support staff and they can help you.</li>
                            </ol>
                            <li>Why was I disqualified immediately without being asked anything?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>If you were disqualified without being asked any questions, it's likely that one of the following occurred:</li>

                                <ul className={`${styles['bullet-list']}`}>
                                    <li>The quota matching your demographic has already been filled. Our system remembers your responses to past survey questions such as age, gender, or location. If the quota group you would qualify for based on these or other demographic criteria has already been filled you will automatically be disqualified. This is done to save you the trouble of having to answer questions unnecessarily.</li>
                                    <li>The survey has already been completed by someone using the same internet connection. This is common when using a shared internet such as public WIFI, at a school or library, or when other members of your household are also members of the MySoapBox community.</li>
                                    <li>The study reached the maximum number of completes and has been closed.</li>
                                </ul>

                                <li>If you have further questions on why you were disqualified, or any other survey related issue, please contact us at support@mysoapbox.com.</li>
                            </ol>
                            <li>Why didn't I qualify for the study?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Our clients establish certain qualification requirements based on what they need to use the research for. Our goal is to provide you with as many opportunities as possible to qualify, but you may not always be exactly what our client is looking for. We encourage you to keep trying for your best chance to earn rewards and make a difference!</li>
                            </ol>
                            <li>I tried to do a survey, but I was not allowed in because the survey was closed?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Each of our research studies runs for a defined length of time. On average, our studies are open for a few days. Some studies stay open longer, and some available for a very short time. You have the option to allow us to contact you via multiple options including email and text message, which ensures you are alerted as soon as we have an opportunity available for you. You may update your invitation settings at any time to increase your chance of being included in a study.</li>
                            </ol>
                            <li>How often can I take surveys?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>The amount of surveys you can take will vary. Based on the information you provide to us at registration and in your profile and the needs of our clients, we do our best to match you with the open opportunities we have at any given time. You can also log into your account and see if there are any opportunities for you. We are adding new opportunities every day, so if there is not anything currently available, you will get something soon.</li>
                            </ol>
                            <li>I signed up for MySoapBox panel but have never received any emails?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>You will not receive emails from us until you have confirmed your email. If you did not receive our activation email which would have been sent to you immediately after you signed up, it may mean your email provider may be flagging our emails as SPAM. You may find our emails in you JUNK box. To make sure you receive all MySoapBox email communications, please add info@mysoapbox.com to your list of trusted senders, address book or contact list.</li>
                            </ol>
                            <li>Why are some research surveys not with MySoapBox</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>MySoapBox wants to give you as many opportunities to participate in research and earn rewards as possible. We often partner with other companies to offer additional opportunities to our members. It is our goal to make sure you always have a good experience, so if anything does not seem right to you, let us know!</li>
                            </ol>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${styles['accordion-item']}`} eventKey="4">
                    <Accordion.Header className={`${styles['accordion-header']}`}>Privacy</Accordion.Header>
                    <Accordion.Body>
                        <ol className={`${styles['numbered-list']}`}>
                            <li>Where can I find the MySoapBox Privacy Policy?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Our link to our Privacy Policy can be found in the footer of any page on the MySoapBox website.</li>
                            </ol>
                            <li>Do you sell my email address or will I get on any lists?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>We would never willfully sell, trade, rent, disclose, or make available your personal information including email address to any outside third party without receiving your authorization to do so, or unless we are legally required to do so by a court decision or by a public authority.</li>
                            </ol>
                            <li>Why do you collect my personal information?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Your personal information is used as a means to contact you about your membership and survey participation. It also helps us to match surveys with your interests as well as fill and manage your incentives. The information you provide us is strictly used for research purposes only. We will not share with our clients or partners without your authorization.</li>
                            </ol>
                            <li>How do you store and protect my personal information?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>All member data collected during registration and through survey participation resides in a state-of-the-art SAS-70 Certified data center that employs comprehensive electronic and physical security.</li>
                            </ol>
                            <li>How will MySoapBox contact me?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>We will contact you by whatever means you have agreed to. Ideally, you allow us to contact you in a variety of ways to ensure we have the best chance of alerting you to opportunities. Our most common contact method is email, however we can text or even call you!</li>
                            </ol>
                            <li>Why do you want my mobile number?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>We know many of our members spend a LOT of time with their cell phones (we also know some of you even sleep with them). Providing us your mobile allows us another way to contact you. We can send you texts and with some research, you can even conduct it right from your phone! The more ways we have to reach you, and the more types of research you agree to be part of, the more chances you have to participate, earn rewards and have fun.</li>
                            </ol>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className={`${styles['accordion-item']}`} eventKey="5">
                    <Accordion.Header className={`${styles['accordion-header']}`}>Technical</Accordion.Header>
                    <Accordion.Body>
                        <ol className={`${styles['numbered-list']}`}>
                            <li>When I enter my password, I am getting an error message. What should I do? </li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Please double check your email and password. If you are sure you are entering the correct, you may need to request a new password.</li>
                            </ol>
                            <li>I got a "Page Not Found" error. What does that mean? </li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>That’s troubling! We thoroughly test our site and links to ensure all are functional and up-to-date. If you receive such an error while accessing our website, we request you contact us, and we apologize for any inconvenience.</li>
                            </ol>
                            <li>Can I use any web browser to access the MySoapBox website?</li>
                            <ol className={`${styles['alpha-list']}`}>
                                <li>Of course, you can even access it on your smartphone or tablet. </li>
                            </ol>
                        </ol>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};
