import { useState } from 'react';

//API INSTANCE
import axiosInstance from './axiosInstance';

export const useRegisterNewUser = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  // heads up! this is currently returning response.data
  // or null if there is an error
  // this has implications in src/components/FormRegisterNewUser/FormRegisterNewUser.jsx
  // which has convoluted logic to handle the null
  // there is also src/_apis/axiosInterceptor.js, maybe it is
  // better to handle the error there?
  // especially considering the "error" could be 500, or 409 etc

  const register = async (options) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_REGISTER_USER_URL}`, {
        first_name: options.firstName,
        last_name: options.lastName,
        email: options.email,
        confirm_email: options.confirmEmail,
        password: options.password,
        confirm_password: options.confirmPassword,
        user_winning_choice: options.userWinningChoice,
        user_meta: {
            dob: options.dob,
            country: options.country,
            zip_code: options.zipCode,
            recruit_url: options.userRecruitURL,
            terms_accepted: options.agreedToTerms,
          }
      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setErrors(err.response.data.errors);
      setLoading(false);
      return null;
    }
  };

  return { loading, errors, register };
};
