import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./Support.module.css";

// COMPONENTS
import { Layout } from '../../layouts/Layout';
import { SupportSearch } from '../../components/SupportSearch';
import { FAQAccordion } from '../../components/FAQAccordion';
import { ContactUs } from '../../components/ContactUs';

export const Support = () => {
  return (
    <Layout>
     <div className={`${styles['page-content']}`}>
        <SupportSearch />
        <FAQAccordion />
        <ContactUs />
    </div>
    </Layout>
  );
};
