import React from 'react';
import { ReportGenerator } from '../../components/ReportGenerator';
import { ReportAuth } from '../../components/ReportAuth';

export const Report = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  if (!isAuthenticated) {
    return <ReportAuth onAuthenticationSuccess={() => setIsAuthenticated(true)} />;
  }

  return <ReportGenerator />;
};
