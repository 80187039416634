import React from 'react';
import { Form, Container, Button } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from "./ContactUs.module.css";

export const ContactUs = () => {
    return (
        <div className={`${styles['flex-container']}`}>
            <Container>
                <Row className={styles['title']}>
                    <p>Contact Us</p>
                </Row>
                <Form className={styles['form']}>
                    <Form.Group>
                        <Form.Control
                            placeholder="Name"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            placeholder="Email"
                        />
                    </Form.Group>
                    <Form.Group>

                        <Form.Control
                            placeholder="Subject"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            className={styles['message-box']}
                            placeholder="Message"
                        />
                    </Form.Group>
                    
                    <Button bsPrefix={styles['submit-button']} type="submit">
                        Send
                    </Button>
                </Form>
            </Container>
        </div>
    );
};
