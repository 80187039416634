import React from 'react'
import Container from 'react-bootstrap/Container';

import "./style.css";

import footerLogo from "../../assets/img/logo-footer-1.png";

export const Footer = () => {
  return (
    <footer className="py-3 my-4 text-center">
      <ul className="nav justify-content-center">
        <li className="nav-item"><a href="https://www.mysoapbox.com/page/551" className="nav-link px-2"  target="_blank" rel="noreferrer">About Us</a></li>
        <li className="nav-spacer">|</li>
        <li className="nav-item"><a href="https://www.mysoapbox.com/page/554" className="nav-link px-2"  target="_blank" rel="noreferrer">Terms of Use</a></li>
        <li className="nav-spacer">|</li>
        <li className="nav-item"><a href="https://www.materialplus.io/privacy-policy" className="nav-link px-2"  target="_blank" rel="noreferrer">Privacy Policy</a></li>
        <li className="nav-spacer">|</li>
        <li className="nav-item"><a href="http://www.soapboxsample.com/" className="nav-link px-2"  target="_blank" rel="noreferrer">Corporate Site</a></li>
        <li className="nav-spacer">|</li>
        <li className="nav-item"><a href="https://www.mysoapbox.com/page/818" className="nav-link px-2"  target="_blank" rel="noreferrer">Support</a></li>
      </ul>

      <img id="footer-logo" src={footerLogo} alt="MySoapBox Logo"/>
      <p>Copyright © 2023 MySoapBox. All Rights Reserved.</p>
      <div id="ccpa_wrapper"><a href="https://www.isacorp.com/your-data-your-rights/"  target="_blank" rel="noreferrer"><p className="ccpa">CCPA - Do Not Sell/Share My Information</p></a></div>
      <p>Limit the Use of My Sensitive Personal Information</p>
        <p>
          (Opting out per the above would result in your removal from our panel)<br/>
          The SOAPBOX trademark is owned by Calign, Inc. and is used under license.
        </p>
    </footer>
  );
};
