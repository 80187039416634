import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./TermsOverview.module.css";

export const TermsOverview = () => {
    return (
        <div className={`${styles['flex-container']} ${'pt-3'}`}>
            <Row className={styles['title']}>
                <p>Terms of Use</p>
            </Row>
            <Row>
                <h7 className={styles['sub-title']}>NOTICE OF ARBITRATION</h7>

                <div className={styles['notice-arbitration']}>

                    <p>YOUR USE OF THE WEBSITE AND YOUR PARTICIPATION IN ANY RESEARCH AND OUR SERVICES ARE SUBJECT TO BINDING INDIVIDUAL ARBITRATION OF ANY DISPUTES WHICH MAY ARISE, AS PROVIDED IN SECTION 14 OF THESE TERMS OF USE.</p>
                </div>

                <h7 className={styles['sub-title']}>INTRODUCTION</h7>
                <div className={styles['intro']}>
                    <p>Welcome to MySoapBox™, the website (the “Website”) of SoapBoxSample™ (the “Company”). Where appropriate, the term “Company” refers both to the Company and its affiliates.</p>

                    <p>All users of the Website are subject to the following terms and conditions (these “Terms of Use”).</p>

                    <p>Please read the following Terms of Use carefully because it will apply to your access to and use of the Website, and constitute a legally binding agreement between you and the Company. Use of the Website constitutes your acceptance to be bound by these Terms of Use. Please note that you may not use the Website and may not accept these Terms of Use if you are under the age of 13 or are a person barred from using the Website under the laws of the United States or other countries, including the country in which you are resident or from which you use the Website.</p>

                    <p>The Company may revise and update these Terms of Use at any time without notice by posting the amended terms to the Website. Your continued use of the Website means that you accept and agree to the revised Terms of Use. If you disagree with the Terms of Use (as amended from time to time) or are dissatisfied with the Website, your sole and exclusive remedy is to discontinue using the Website. THE MOST CURRENT VERSION OF THIS AGREEMENT, WHICH SUPERSEDES ALL PREVIOUS VERSIONS, CAN BE REVIEWED BY CLICKING ON THE “TERMS AND CONDITIONS” HYPERLINK LOCATED ON THE WEBSITE.	</p>
                </div>
            </Row>
        </div>
    );
};
