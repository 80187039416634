import React from 'react';
import { Row, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./AboutUsOverview.module.css";

export const AboutUsOverview = () => {
    return (
        <div className={`${styles['flex-container']}`}>
            <Row className={styles['title']}>
                <p>About Us</p>
            </Row>
            <Row className={styles['separator']}>

            </Row>
            <Row className={styles['body']}>
                <p>MySoapBox™ is an online research community. We collect feedback from our members on products, services, and experiences.</p>

                <p>The most successful brands depend on our members to help them evaluate how they are doing, what they need to do better, and what they should create in the future. </p>
            </Row>

            <Row>
                <Button bsPrefix={styles['join-button']}>Join Now</Button>
            </Row>
        </div>
    );
};
