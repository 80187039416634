import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./AboutUsIcons.module.css";

export const AboutUsIcons = () => {
    return (
        <div className={`${styles['flex-container']}`}>
            <Row className={`${styles['icon-row']}`}>
                <Col className={`${styles['icon-container']}`}>
                    <Row className={`${styles['icon-megaphone']}`}></Row>
                    <Row className={`${styles['text-container']}`}>Your < br/> Voice</Row>
                </Col>
                <Col className={`${styles['icon-container']}`}>
                    <Row className={`${styles['icon-reward']}`}></Row>
                    <Row className={`${styles['text-container']}`}>Your < br/> Rewards</Row>
                </Col>
                <Col className={`${styles['icon-container']}`}>
                    <Row className={`${styles['icon-happy']}`}></Row>
                    <Row className={`${styles['text-container']}`}>Your < br/> Decisions</Row>
                </Col>
                <Col className={`${styles['icon-container']} ${styles['bottom-row']}`}>
                    <Row className={`${styles['icon-bullseye']}`}></Row>
                    <Row className={`${styles['text-container']}`}>Your < br/> Impact</Row>
                </Col>
                <Col className={`${styles['icon-container']} ${styles['bottom-row']}`}>
                    <Row className={`${styles['icon-box']}`}></Row>
                    <Row className={`${styles['text-container']}`}>Your < br/> Soapbox</Row>
                </Col>
            </Row>
        </div>
    );
};
