import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import styles from "./AboutUsTip.module.css";

export const AboutUsTip = () => {
    return (
        <div className={`${styles['flex-container']}`}>
            <Row className={`${styles['title']}`}>Did you know? This year members have...</Row>
            <Row className={`${styles['info']}`}>
                <Col className={`${styles['icon']}`}></Col>
                <Col className={`${styles['list-container']}`}>
                    <ul className={`${styles['bullet-list']}`}>
                        <li>Cashed out over 20,000 gift cards</li>
                        <li>Been paid a whopping total of $470,000+</li>
                        <li>Helpd on over 45,000 studies</li>
                    </ul>
                </Col>
            </Row>

        </div>
    );
};
