// components/SurveyList.jsx
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Container, Button, Alert, Row, Col, Modal, Pagination } from 'react-bootstrap';

// APIS
import useFetchSurveyLink from '../../_apis/useFetchSurveyLink';

// CONTEXTS
import { SurveyListContext } from "../../_contexts/SurveyListContext";
import { ScrollContext } from '../../_contexts/ScrollContext';

// IMAGES

import clockIcon from "../../assets/img/clock-icon.png";
import coinIcon from "../../assets/img/gold-star-icon.png";
import sortArrows from "../../assets/img/sort-arrows.png";

import surveyIcon_1 from "../../assets/img/survey-icon-1.png";
import surveyIcon_2 from "../../assets/img/survey-icon-2.png";
import surveyIcon_3 from "../../assets/img/survey-icon-3.png";
import surveyIcon_4 from "../../assets/img/survey-icon-4.png";
import surveyIcon_5 from "../../assets/img/survey-icon-5.png";

// STYLES

import "./style.css";

function SurveyLinkError({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Uh Oh!</Modal.Title>
      </Modal.Header>
      <Modal.Body>It looks like this survey is not ready just yet. Let's have you try another one. Please close this and try another.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const SurveyList = () => {
  const { surveyList, loading, error } = useContext(SurveyListContext);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);

  // Pagination state variables
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  // Scroll context variables
  const [, setTargetElementRef] = useContext(ScrollContext);

  const targetElementRef = useRef(null);

  useEffect(() => {
    setTargetElementRef(targetElementRef);
  }, [setTargetElementRef]);


  // Pagination handler
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // SurveyLinkError state to manage the visibility of the modal
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  // Initialize the useFetchSurveyLink hook
  const { fetchLink } = useFetchSurveyLink();

  const handleTakeSurvey = async (surveyId) => {
    try {
      const surveyLink = await fetchLink(surveyId);

      if (surveyLink) {
        window.open(surveyLink, '_self');
      } else {
        handleModalShow();
      }
    } catch (error) {
      handleModalShow(); // Show the modal when there's an error
    }
  };

  if (error) {
    return (
      <div className='error-box-wrapper'>
        <Alert variant="warning">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error.message}</p>
          <p className='error-text-smaller'>If this error persists, please contact support at <a href="mailto:support@mysoapbox.com">support@mysoapbox.com</a></p>
        </Alert>
      </div>
    );
  }

  const sortByEarningsPerClick = (a, b) => {
    return b.earnings_per_click - a.earnings_per_click;
  };

  // const filterSurveys = (survey) => {
  //   return (
  //     survey.bid_length_of_interview <= 30 &&
  //     survey.bid_incidence >=70

  //   );
  // };

  // presort the surveyList data by EPC
  // const filteredSurveys = surveyList && surveyList.data.filter(filterSurveys);
  const sortedByEPC = surveyList && surveyList.slice().sort(sortByEarningsPerClick);

  // Create sortedData so user can sort survey list by survey length or survey_points
  const sortedData = sortedByEPC && sortedByEPC.slice().sort((a, b) => {
    if (sortField === 'surveyLength') {
      return sortDirection * (a.bid_length_of_interview - b.bid_length_of_interview);
    } else if (sortField === 'survey_points') {
      return sortDirection * (a.survey_points - b.survey_points);
    }

    return 0;
  });

  // Slice the sortedData array based on the current page and items per page
  const paginatedData = sortedData && sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Create pagination items
  const paginationItems = [];
  if (sortedData) {
    const totalPages = Math.ceil(sortedData.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection * -1);
    } else {
      setSortField(field);
      setSortDirection(1);
    }
  };

  if (loading) {
    return (
      <div className='loading-box-wrapper'>
      <Alert variant="info">
        <Alert.Heading>Loading Surveys...</Alert.Heading>
        <p className='error-text-smaller'>This will only take a moment...</p>
      </Alert>
      </div>
    );
  }

  const surveyIconArray = [
    surveyIcon_1,
    surveyIcon_2,
    surveyIcon_3,
    surveyIcon_4,
    surveyIcon_5
  ]

  const cpiDisplayArray = [
    { condition: (survey_points) => survey_points >= 1500, display: '1500' },
    { condition: (survey_points) => survey_points >= 1000 && survey_points < 1499, display: '1000' },
    { condition: (survey_points) => survey_points >= 750 && survey_points < 999, display: '750' },
    { condition: (survey_points) => survey_points <= 500, display: '500' },
  ];

  const getCpiDisplay = (survey_points) => {
    const cpiDisplay = cpiDisplayArray.find((item) => item.condition(survey_points));
    return cpiDisplay ? cpiDisplay.display : `${survey_points} Points`;
  };

  return (
    <Container className="survey-container" fluid>
      <SurveyLinkError show={showModal} handleClose={handleModalClose} />
       {/* Desktop view */}
        <Row className="desktop-sort-row">
          <Col className="survey-num-header" xs={12} md={3} lg={3}>
              Survey #
          </Col>
          <Col className='header-text length-label' xs={12} md={3} lg={3}>
            <Button variant="link" onClick={() => handleSort('surveyLength')}>
              <img src={sortArrows} alt="sort-icon" id="sort-icons" /> Length
            </Button>
          </Col>
          <Col className='header-text points-label' xs={12} md={3} lg={3}>
            <Button variant="link" onClick={() => handleSort('survey_points')}>
              <img src={sortArrows} alt="sort-icon" id="sort-icons" /> Points
            </Button>
          </Col>
          <Col className='button-cell'></Col>
        </Row>
        {/* Mobile view */}
        <Row className="mobile-sort-row">
          <Col className="mobile-header-text sort-by-label">
            Sort by:
          </Col>
          <Col className="mobile-header-text length-label">
            <Button
              variant="link"
              onClick={() => handleSort('surveyLength')}
              className={sortField === 'survey_points' ? 'grey-out' : ''}
            >
               Length
               <img src={sortArrows} alt="sort-icon" id="sort-icons" />
            </Button>
          </Col>
          <Col className="mobile-header-text points-label">
            <Button
              variant="link"
              onClick={() => handleSort('survey_points')}
              className={sortField === 'surveyLength' ? 'grey-out' : ''}
            >
               Points
               <img src={sortArrows} alt="sort-icon" id="sort-icons" />
            </Button>
          </Col>
        </Row>

        {paginatedData && paginatedData.length > 0 ? (
          paginatedData.map((survey, index) => {
          const icon = surveyIconArray[index % surveyIconArray.length];

          return (
            <Row key={index} className='survey-table-rows' >
              <Col className="survey-name-col" xs={12} md={3} lg={3}>
                <img src={icon} alt={`survey-icon-${index}`} className="survey-icons"/>
                &nbsp;&nbsp;{survey.survey_id}
              </Col>
              <Col className='survey-length-col survey-cell' xs={12} md={3} lg={3}>
                <img src={clockIcon} alt="clock-icon" />
                {survey.bid_length_of_interview} Minutes
              </Col>
              <Col className='survey-points-col survey-cell' xs={7} md={3} lg={3}>
                <img src={coinIcon} alt="gold-star-icon" />
                {getCpiDisplay(survey.survey_points)} Points
              </Col>

              <Col  xs={2} md={3} lg={3} className='survey-btn-col'>
                <Button
                  className='survey-btn'
                  onClick={() => handleTakeSurvey(survey.id)} // Call handleTakeSurvey with the survey ID
                >
                  Start Survey
                </Button>
              </Col>
            </Row>
          );
        })
        ) : (
          <Alert variant="info">
            <Alert.Heading>Working on Finding Surveys...</Alert.Heading>
            <p className='error-text-smaller'>Looks like we have no other survey matches for you at the moment. If you wait a few minutes and refresh, more surveys may appear. Otherwise, please check back later. Keep in mind this is a BETA environment, and we are continuing to work on better matches for you. Please be sure you are providing accurate information with every attempt.  Check your inbox for other survey opportunities. Thank you for your participation! </p>
          </Alert>
        )}

      <Row>
        <Col>
          <Pagination className="justify-content-center mt-3">{paginationItems}</Pagination>
        </Col>
      </Row>
    </Container>
  );
};
