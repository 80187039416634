import React from "react";

import { Container, Row, Dropdown } from "react-bootstrap";

import styles from "./SupportSearch.module.css";

export const SupportSearch = () => {
    return (
        <Container className={`${styles['flex-container']}`}>
            <Row className={`${styles['title']}`}>
                <p>Support</p>
            </Row>
            <Dropdown bsPrefix={`${styles['dropdown-container']}`}>
                <Dropdown.Toggle bsPrefix={`${styles['dropdown-button']}`} id="dropdown-basic">
                    Search
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Container>
    );
};
